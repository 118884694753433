input[type='range'] {
    overflow: hidden;
    -webkit-appearance: none;
    background-color: #9a905d;
    outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
}

input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -480px 0 0 480px darkorange;
}


/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: #43e5f7;
}
input[type="range"]::-moz-range-track {
    background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: #43e5f7;
}
input[type="range"]::-ms-fill-upper {
    background-color: #9a905d;
}